import React from "react"
import { bp, fonts, colors } from "../components/Styles"
import styled from "styled-components"

const TextContainer = styled.div<{ spacing: Spacing }>`
  > h3 {
    line-height: 2.2rem;
    font-size: 1.3rem;
    font-family: ${fonts.bold};

    ${bp("mobile")`
    font-size: 1.2rem;
    `}
  }

  > p,
  a {
    font-size: 1.2rem;
    line-height: 3.1rem;
    padding: ${({ spacing }) => spacings[spacing]} 0;
    max-width: 100%;
  }
`

const spacings = {
  small: ".5rem",
  medium: "2rem",
}
type Spacing = keyof typeof spacings

export const TextComponent: React.FC<{ title: string; spacing?: Spacing }> = ({
  title,
  spacing = "medium",
  children,
}) => (
  <TextContainer spacing={spacing}>
    <h3>{title}</h3>

    {children}
  </TextContainer>
)

const ColumnContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  ${bp("mobile")`
        flex-direction: column;
    `}

  > :first-child {
    flex: 0 0 35%;
    padding-right: 4rem;

    > h3 {
      font-family: ${fonts.bold};
      color: ${colors.primary};
      line-height: 2.2rem;
      font-size: 1.3rem;

      ${bp("mobile")`
            font-size: 1.2rem;
        `}
    }
  }

  > :last-child {
    flex: 0 0 65%;
  }
`

export const Columns: React.FC<{
  left: React.ReactNode
  right: React.ReactNode
}> = ({ left, right }) => (
  <ColumnContainer>
    <div>{left}</div>

    <div>{right}</div>
  </ColumnContainer>
)
