import {
  Container,
  HeroHeader,
  HeadlineContainer,
  Content,
  MinSpacing,
} from "../components/layout-components"
import { graphql } from "gatsby"
import React from "react"
import { asBg } from "../util/image"
import { Columns, TextComponent } from "../components/text-components"
import Footer from "../components/Footer"
import { useTranslation } from "gatsby-plugin-react-i18next"
import { Helmet } from "react-helmet"

const LegalNotice: React.FC<{ data: any }> = ({ data }) => {
  const { headerBackground } = data
  const { t } = useTranslation()

  return (
    <Container>
      <Helmet>
        <title>Garage51 | {t("Impressum")}</title>
      </Helmet>

      <HeroHeader {...asBg(headerBackground)} height="30rem">
        <HeadlineContainer align="left">
          <h1>{t("Impressum")}</h1>
        </HeadlineContainer>
      </HeroHeader>

      <Content>
        <MinSpacing />
        <Columns
          left={
            <TextComponent title={t("Büro- und Kontaktadresse")}>
              <p>
                {t("GARAGE 51 GmbH")} <br />
                {t("Daimlerstraße 32")}
                <br />
                {t("60314 Frankfurt")}
                <br />
              </p>

              <p>
                {t("Gesellschafter")}
                <br />
                {t("Anil Cakar, Ben Jurczyga, Johanna Jurczyga")}
              </p>

              <p>
                {t("Geschäftsführer")}
                <br />
                {t("Johanna Jurczyga")}
              </p>

              <p>
                {t("Telefon: +49 69-34 87 65 66")}
                <br />
                {t("E-Mail: all@garage51.de")}
              </p>

              <p>
                {t("Registergericht: Amtsgericht Frankfurtt")}
                <br />
                {t("Registernummer: HRB 99645 ")}
              </p>

              <p>
                {t("USt-ID: DE295802249")}
                <br />
                {t("VERANTWORTLICH FÜR GRAFIK UND INHALT")}
                <br />
                {t("GARAGE 51")}
              </p>
            </TextComponent>
          }
          right={
            <>
              <TextComponent title={t("HAFTUNG FÜR INHALTE")}>
                <p>
                  {t(
                    "Als Diensteanbieter sind wir gemäß § 7 Abs.1 TMG für eigene Inhalte auf diesen Seiten nach den allgemeinen Gesetzen verantwortlich. Nach §§ 8 bis 10 TMG sind wir als Diensteanbieter jedoch nicht verpflichtet, übermittelte oder gespeicherte fremde Informationen zu überwachen oder nach Umständen zu forschen, die auf eine rechtswidrige Tätigkeit hinweisen. Verpflichtungen zur Entfernung oder Sperrung der Nutzung von Informationen nach den allgemeinen Gesetzen bleiben hiervon unberührt. Eine diesbezügliche Haftung ist jedoch erst ab dem Zeitpunkt der Kenntnis einer konkreten Rechtsverletzung möglich. Bei Bekanntwerden von entsprechenden Rechtsverletzungen werden wir diese Inhalte umgehend entfernen."
                  )}
                </p>
              </TextComponent>
              <TextComponent title={t("HAFTUNG FÜR LINKS")}>
                <p>
                  {t(
                    "Unser Angebot enthält Links zu externen Webseiten Dritter, auf deren Inhalte wir keinen Einfluss haben. Deshalb können wir für diese fremden Inhalte auch keine Gewähr übernehmen. Für die Inhalte der verlinkten Seiten ist stets der jeweilige Anbieter oder Betreiber der Seiten verantwortlich. Die verlinkten Seiten wurden zum Zeitpunkt der Verlinkung auf mögliche Rechtsverstöße überprüft. Rechtswidrige Inhalte waren zum Zeitpunkt der Verlinkung nicht erkennbar. Eine permanente inhaltliche Kontrolle der verlinkten Seiten ist jedoch ohne konkrete Anhaltspunkte einer Rechtsverletzung nicht zumutbar. Bei Bekanntwerden von Rechtsverletzungen werden wir derartige Links umgehend entfernen."
                  )}
                </p>
              </TextComponent>
              <TextComponent title={t("URHEBERRECHT")}>
                <p>
                  {t(
                    "Die durch die Seitenbetreiber erstellten Inhalte und Werke auf diesen Seiten unterliegen dem deutschen Urheberrecht. Die Vervielfältigung, Bearbeitung, Verbreitung und jede Art der Verwertung außerhalb der Grenzen des Urheberrechtes bedürfen der schriftlichen Zustimmung des jeweiligen Autors bzw. Erstellers. Downloads und Kopien dieser Seite sind nur für den privaten, nicht kommerziellen Gebrauch gestattet. Soweit die Inhalte auf dieser Seite nicht vom Betreiber erstellt wurden, werden die Urheberrechte Dritter beachtet. Insbesondere werden Inhalte Dritter als solche gekennzeichnet. Sollten Sie trotzdem auf eine Urheberrechtsverletzung aufmerksam werden, bitten wir um einen entsprechenden Hinweis. Bei Bekanntwerden von Rechtsverletzungen werden wir derartige Inhalte umgehend entfernen. Garage51 übernimmt keinerlei Gewähr für die Aktualität, Korrektheit, Vollständigkeit oder Qualität der bereitgestellten Informationen. Haftungs-Ansprüche, die durch die Nutzung oder Nichtnutzung der dargebotenen Informationen bzw. bdurch die Nutzung fehlerhafter und unvollständiger Informationen verursacht wurden und sich auf Schäden materieller oder ideeller Art beziehen, sind grundsätzlich ausgeschlossen. Alle Angebote sind freibleibend und unverbindlich. Garage51 behält es sich ausdrücklich vor, Teile der Seiten oder das gesamte Angebot ohne gesonderte Ankündigung zu verändern, zu ergänzen, zu löschen oder die Veröffentlichung zeitweise oder endgültig einzustellen."
                  )}
                </p>
              </TextComponent>
            </>
          }
        />
        <MinSpacing />
      </Content>

      <Footer />
    </Container>
  )
}

export default LegalNotice

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(
      filter: {
        ns: { in: ["common", "legalnotice"] }
        language: { eq: $language }
      }
    ) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }

    headerBackground: file(relativePath: { eq: "slides/Slide_1.jpg" }) {
      childImageSharp {
        gatsbyImageData(width: 1920, height: 1080)
      }
    }
  }
`
